.reviews__card__profile {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 15px 20px 0 ;
}

@media screen and (max-width: 568px) {
  .reviews__card__profile {
    margin: 10px 15px 0 ;
  }
}