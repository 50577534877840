.header__logo__type_image {
  height: 95px;
  align-self: baseline;
}

@media screen and (max-width: 984px) {
  .header__logo__type_image {
    height: 75px;
  }
}
@media screen and (max-width: 568px) {
  .header__logo__type_image {
    height: 45px;
  }
}