.reviews__cards {
  padding: 0 150px;
}

@media screen and (max-width: 984px) {
  .reviews__cards {
    padding: 0 70px;
  }
}

@media screen and (max-width: 568px) {
  .reviews__cards {
    padding: 0 25px;
  }
}