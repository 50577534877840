.header__info {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  margin-right: 150px;
}

@media screen and (max-width: 984px) {
  .header__info {
    margin-right: 70px;
  }
}
@media screen and (max-width: 568px) {
  .header__info {
    margin-right: 25px;
  }
}
