.connection__subtitle {
  font-size: 22px;
  font-weight: 400;
  line-height: 22px;
  margin: 0;
  padding: 30px 150px 0;
  text-align: center;
}

.connection__subtitle_br {
  display: none;
}

@media screen and (max-width: 984px) {
  .connection__subtitle {
    font-size: 18px;
    line-height: 22px;
    padding: 30px 70px 0;
  }
}

@media screen and (max-width: 644px) {
  .connection__subtitle_br {
    display: block;
  }
}

@media screen and (max-width: 568px) {
  .connection__subtitle {
    font-size: 14px;
    line-height: 18px;
    padding: 20px 25px 0;
  }
}