.header {
  background-color: #f7f7f8;
  max-width: 1280px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  height: 100px;
  align-items: center;
  color: #0f205e;
  justify-content: space-between;
}

@media screen and (max-width: 984px) {
  .header {
    height: 80px;
  }
}
@media screen and (max-width: 568px) {
  .header {
    height: 50px;
  }
}