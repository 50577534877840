.reviews__card__name {
  padding-left: 10px;
  font-weight: 500;
  font-size: 16px;
  margin: 0;
}

@media screen and (max-width: 984px) {
  .reviews__card__name {
    font-size: 16px;
    line-height: 16px;
  }
}
@media screen and (max-width: 568px) {
  .reviews__card__name {
    font-size: 14px;
    line-height: 14px;
  }
}