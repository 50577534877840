.info__title {
  font-size: 24px;
  font-weight: 500;
  line-height: 24px;
  margin: 0;
  padding-bottom: 30px;
}

@media screen and (max-width: 1050px) {
  .info__title {
    font-size: 18px;
    line-height: 18px;
    padding-bottom: 20px;
  }
}

@media screen and (max-width: 720px) {
  .info__title {
    font-size: 16px;
    line-height: 16px;
  }
}

@media screen and (max-width: 629px) {
  .info__title {
    text-align: center;
  }
}