.footer__description {
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  margin: 50px 0 0;
  padding-bottom: 50px;
  border-bottom: #f7f7f8 1px solid;
  text-align: left;
  width: 100%;
}

@media screen and (max-width: 984px) {
  .footer__description {
    font-size: 12px;
    margin: 30px 0 0;
    padding-bottom: 30px;
  }
}