body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@import url(./vendor/normalize.css);
@import url(./vendor/fonts/fonts.css);

@import url(./blocks/page/page.css);
@import url(./blocks/header/header.css);
@import url(./blocks/header/__logo/header__logo.css);
@import url(./blocks/header/__logo/__type/header__logo__type_image.css);
@import url(./blocks/header/__logo/__type/header__logo__type_title.css);
@import url(./blocks/header/__info/header__info.css);
@import url(./blocks/header/__info/__type/header__info__type_number.css);
@import url(./blocks/header/__info/__type/header__info__type_time.css);
@import url(./blocks/lead/lead.css);
@import url(./blocks/lead/__title/lead__title.css);
@import url(./blocks/lead/__container/lead__container.css);
@import url(./blocks/lead/__slogan/lead__slogan.css);
@import url(./blocks/lead/__button/lead__button.css);
@import url(./blocks/menu/menu.css);
@import url(./blocks/menu/__nav/menu__nav.css);
@import url(./blocks/menu/__item/menu__item.css);
@import url(./blocks/menu/__item/__link/menu__item__link.css);
@import url(./blocks/menu/__container/menu__container.css);
@import url(./blocks/help/help.css);
@import url(./blocks/help/__title/help__title.css);
@import url(./blocks/help/__logo/help__logo.css);
@import url(./blocks/help/__cards/help__cards.css);
@import url(./blocks/help/__card/help__card.css);
@import url(./blocks/help/__card/__corner/help__card__corner.css);
@import url(./blocks/help/__card/__img/help__card__img.css);
@import url(./blocks/help/__card/__description/help__card__description.css);
@import url(./blocks/help/__card/__button/help__card__button.css);
@import url(./blocks/help/__card/__title/help__card__title.css);
@import url(./blocks/statistics/statistics.css);
@import url(./blocks/statistics/__container/statistics__container.css);
@import url(./blocks/statistics/__description/statistics__description.css);
@import url(./blocks/statistics/__info-container/statistics__info-container.css);
@import url(./blocks/statistics/__info/statistics__info.css);
@import url(./blocks/statistics/__title/statistics__title.css);
@import url(./blocks/reviews/reviews.css);
@import url(./blocks/reviews/__card/reviews__card.css);
@import url(./blocks/reviews/__card/__name/reviews__card__name.css);
@import url(./blocks/reviews/__card/__photo/reviews__card__photo.css);
@import url(./blocks/reviews/__card/__profile/reviews__card__profile.css);
@import url(./blocks/reviews/__card/__review/reviews__card__review.css);
@import url(./blocks/reviews/__cards/reviews__cards.css);
@import url(./blocks/reviews/__container/reviews__container.css);
@import url(./blocks/reviews/__subtitle/reviews__subtitle.css);
@import url(./blocks/reviews/__title/reviews__title.css);
@import url(./blocks/tired/tired.css);
@import url(./blocks/tired/__container/tired__container.css);
@import url(./blocks/tired/__director/tired__director.css);
@import url(./blocks/tired/__title/tired__title.css);
@import url(./blocks/tired/__cards/tired__cards.css);
@import url(./blocks/tired/__card/tired__card.css);
@import url(./blocks/tired/__card/__description/tired__card__description.css);
@import url(./blocks/tired/__card/__image/tired__card__image.css);
@import url(./blocks/connection/connection.css);
@import url(./blocks/connection/__title/connection__title.css);
@import url(./blocks/connection/__subtitle/connection__subtitle.css);
@import url(./blocks/connection/__form/connection__form.css);
@import url(./blocks/connection/__container/connection__container.css);
@import url(./blocks/form/__input/form__input.css);
@import url(./blocks/form/__button/form__button.css);
@import url(./blocks/info/info.css);
@import url(./blocks/info/__container/info__container.css);
@import url(./blocks/info/__contacts/info__contacts.css);
@import url(./blocks/info/__map/info__map.css);
@import url(./blocks/info/__text/info__text.css);
@import url(./blocks/info/__time/info__time.css);
@import url(./blocks/info/__title/info__title.css);
@import url(./blocks/footer/footer.css);
@import url(./blocks/footer/__container/footer__container.css);
@import url(./blocks/footer/__copyright/footer__copyright.css);
@import url(./blocks/footer/__description/footer__description.css);
@import url(./blocks/popup/popup.css);
@import url(./blocks/popup/__container/popup__container.css);
@import url(./blocks/popup/__close/popup__close.css);
@import url(./blocks/popup/__form/popup__form.css);
@import url(./blocks/popup/__title/popup__title.css);
@import url(./blocks/popup/__input/popup__input.css);
@import url(./blocks/popup/__button/popup__button.css);