.info__map {
  width: 50%;
  align-self: stretch;
}

@media screen and (max-width: 629px) {
  .info__map {
    height: 270px;
    width: 100%;
  }
}