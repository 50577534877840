.header__logo {
  flex-direction: row;
  display: flex;
  align-self: baseline;
  margin-left: 150px;
}

@media screen and (max-width: 984px) {
  .header__logo {
    margin-left: 70px;
  }
}
@media screen and (max-width: 568px) {
  .header__logo {
    margin-left: 25px;
  }
}