.statistics__info-container {
  max-width: 980px;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  color: #f7f7f8;
  margin: 0 auto;
  padding: 145px 150px;
}

@media screen and (max-width: 984px) {
  .statistics__info-container {
    padding: 100px 70px;
  }
}

@media screen and (max-width: 568px) {
  .statistics__info-container {
    flex-direction: column;
    justify-items: center;
    padding: 100px 25px;
  }
}