.lead__title {
  font-size: 54px;
  margin: 0;
  padding: 150px 150px 0;
  font-weight: 500;
  text-align: center;
}

@media screen and (max-width: 984px) {
  .lead__title {
    font-size: 32px;
    padding: 100px 70px 0;
  }
}
@media screen and (max-width: 568px) {
  .lead__title {
    font-size: 18px;
    padding: 150px 25px 0;
  }
}