.reviews__subtitle {
  font-size: 24px;
  font-weight: 400;
  line-height: 30px;
  margin: 30px 150px 60px;
  text-align: center;
}

.br {
  display: none;
}

@media screen and (max-width: 984px) {
  .reviews__subtitle {
    font-size: 18px;
    line-height: 22px;
    margin: 20px 70px 60px
  }
}
@media screen and (max-width: 568px) {
  .reviews__subtitle {
    font-size: 12px;
    line-height: 16px;
    margin: 20px 25px 40px;
    width: 213px;
  }
  .br {
    display:block;
  }
}