.help__card {
  width: 250px;
  height: 350px;
  box-shadow: #fafbff -20px -20px 40px, #a6abbd 10px 10px 25px;
  border-radius: 20px;
  border: 4px solid rgba(255, 255, 255, 0.16);
  background-color: #eaebef;
  display: flex;
  flex-direction: column;
  position: relative;
}