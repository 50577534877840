.statistics__info {
  width: min-content;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media screen and (max-width: 568px) {
  .statistics__info {
    margin-bottom: 35px;
  }
}

@media screen and (max-width: 568px) {
  .statistics__info:last-of-type {
    margin-bottom: 0;
  }
}