.reviews__card__review {
  display: block;
  margin: 5px 20px 20px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
}

@media screen and (max-width: 984px) {
  .reviews__card__review {
    margin: 3px 20px 15px;
  }
}
@media screen and (max-width: 568px) {
  .reviews__card__review {
    font-size: 12px;
    line-height: 16px;
    margin: 1px 15px 10px;
  }
}