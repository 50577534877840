.tired__cards {
  margin-left: 30px;
  margin-top: 20px;
}

@media screen and (max-width: 1190px) {
  .tired__cards {
    margin-left: 12px;
    margin-top: 0;
  }
}

@media screen and (max-width: 568px) {
  .tired__cards {
    margin: 0;
  }
}