.reviews__card {
  background-color: #ebecf0;
  box-shadow: inset -10px -10px 20px #fafbff, inset 10px 10px 20px #a6abbd;
  border: 2px solid rgba(255, 255, 255, 0.16);
  border-radius: 40px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
}

@media screen and (max-width: 984px) {
  .reviews__card {
    margin-bottom: 15px;
  }
}

@media screen and (max-width: 568px) {
  .reviews__card {
    border-radius: 25px;
    margin-bottom: 10px;
  }
}

.reviews__card:last-of-type {
  margin-bottom: 150px;
}

@media screen and (max-width: 984px) {
  .reviews__card:last-of-type {
    margin-bottom: 100px;
  }
}