.footer__copyright {
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  padding: 30px 0 50px 0;
  margin: 0;
  align-self: flex-end;
}

@media screen and (max-width: 984px) {
  .footer__copyright {
    font-size: 12px;
    line-height: 12px;
  }
}

@media screen and (max-width: 568px) {
  .footer__copyright {
    font-size: 10px;
    line-height: 10px;
    padding: 20px 0 30px 0;
  }
}