.statistics__description {
  font-size: 24px;
  line-height: 28px;
  width: max-content;
  text-align: center;
  margin: 0;
}

@media screen and (max-width: 984px) {
  .statistics__description {
    font-size: 18px;
    line-height: 20px;
    width: min-content;
  }
}