.menu__item__link {
  text-decoration: none;
  font-size: 18px;
  color: #f7f7f8;
}

@media screen and (max-width: 984px) {
  .menu__item__link {
    font-size: 16px;
  }
}
@media screen and (max-width: 568px) {
  .menu__item__link {
    font-size: 14px;
  }
}

.menu__item__link:hover {
  opacity: .7;
}