.help__card__button {
  background-color: #0f205e;
  background-image: url(../../../../images/help_arrow.svg);
  background-repeat: no-repeat;
  background-position: center;
  box-shadow: 3px 4px 4px rgba(15, 32, 94, 0.25);
  margin: 0 10px 10px 0;
  width: 35px;
  height: 35px;
  border-radius: 50px;
  border: none;
  padding: 0;
  position: absolute;
  bottom: 0;
  right: 0;
  cursor: pointer;
}

.help__card__button:hover {
  opacity: .9;
}