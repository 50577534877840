.help__logo {
  width: 149px;
  height: 149px;
  margin: 0;
  padding-top: 100px;
}

@media screen and (max-width: 984px) {
  .help__logo {
    width: 90px;
    height: 90px;
    padding-top: 60px;
  }
}
@media screen and (max-width: 568px) {
  .help__logo {
    width: 70px;
    height: 70px;
    padding-top: 30px;
  }
}