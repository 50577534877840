.popup__close {
  background-image: url(../../../images/Close-Icon.svg);
  background-color: transparent;
  border: none;
  width: 32px;
  height: 32px;
  position: absolute;
  top: -40px;
  right: -40px;
  background-size: contain;
  cursor: pointer;
  transition: .6s;
}
.popup__close:hover {
  opacity: .6;
}
@media screen and (max-width: 618px) {
  .popup__close {
      width: 20px;
      height: 20px;
      top: -36px;
      right: 0;
  }
}