.info__text {
  margin: 100px 30px 100px 100px;
  display: flex;
  flex-direction: column;
}

@media screen and (max-width: 1050px) {
  .info__text {
    margin: 70px 30px 70px 30px;
  }
}

@media screen and (max-width: 720px) {
  .info__text {
    margin: 60px 25px;
  }
}

@media screen and (max-width: 629px) {
  .info__text {
    align-self: center;
  }
}