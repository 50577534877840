.form__button {
  background-color: #F1F1F4;
  border: rgba(255, 255, 255, 0.4) 1px solid;
  border-radius: 40px;
  width: 300px;
  height: 60px;
  box-shadow: -5px -5px 10px #FAFBFF, 5px 5px 10px #A6ABBD;
  margin-top: 10px;
  cursor: pointer;
  padding: 0;
  font-size: 18px;
  color: #140A4D;
}

@media screen and (max-width: 984px) {
  .form__button {
    width: 270px;
    height: 45px;
    margin-top: 15px;
  }
}

@media screen and (max-width: 568px) {
  .form__button {
    width: 200px;
    height: 45px;
    margin-top: 10px;
    font-size: 14px;
  }
}

.form__button:active {
  box-shadow: -5px -5px 10px #FAFBFF inset, 5px 5px 10px #A6ABBD inset;
}