.tired__card__description {
  padding-left: 25px;
  font-size: 18px;
  line-height: 20px;
  width: 350px;
  margin: 0;
}

@media screen and (max-width: 1190px) {
  .tired__card__description {
    font-size: 16px;
    line-height: 18px;
    padding-left: 20px;
    width: 254px;
  }
}

@media screen and (max-width: 568px) {
  .tired__card__description {
    font-size: 14px;
    line-height: 16px;
    padding-left: 10px;
    width: 210px;
  }
}