.reviews__card__photo {
  width: 50px;
  height: 50px;
  border-radius: 100%;
}

@media screen and (max-width: 984px) {
  .reviews__card__photo {
    width: 40px;
    height: 40px;
  }
}
@media screen and (max-width: 568px) {
  .reviews__card__photo {
    width: 30px;
    height: 30px;
  }
}