.tired__director {
  width: 414px;
  height: 586px;
  align-self: flex-end;
}

@media screen and (max-width: 1190px) {
  .tired__director {
    width: 268px;
    height: 427px;
  }
}

@media screen and (max-width: 744px) {
  .tired__director {
    display: none;
  }
}