.help__title {
  font-size: 40px;
  padding-top: 60px;
  margin: 0;
}

@media screen and (max-width: 984px) {
  .help__title {
    font-size: 32px;
    padding-top: 40px;
  }
}
@media screen and (max-width: 568px) {
  .help__title {
    font-size: 18px;
    padding-top: 25px;
  }
}