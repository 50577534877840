.lead__button {
  width: 490px;
  height: 60px;
  border-radius: 40px;
  border: none;
  background-color: #ebecf0;
  font-size: 20px;
  color: #0f205e;
  cursor: pointer;
  margin-bottom: 150px;
  outline: none;
}

@media screen and (max-width: 984px) {
  .lead__button {
    font-size: 14px;
    width: 300px;
    height: 40px;
    margin-bottom: 100px;
  }
}
@media screen and (max-width: 568px) {
  .lead__button {
    font-size: 10px;
    width: 200px;
    height: 35px;
  }
}

.lead__button:hover {
  opacity: .9;
}