.popup {
  background-color: rgba(0, 0, 0, 0.6);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  transition: visibility .4s, opacity .4s ease-in;
  visibility: hidden;
  opacity: 0;
  display: flex;
}

.popup_opened {
  opacity: 1;
  visibility: visible;
}