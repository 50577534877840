.help__cards {
  margin: 100px auto 150px;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, 258px);
  column-gap: 100px;
  row-gap: 70px;
  justify-items: center;
  justify-content: center;
}

@media screen and (max-width: 984px) {
  .help__cards {
    margin: 70px auto 100px;
    row-gap: 50px;
  }
}
@media screen and (max-width: 568px) {
  .help__cards {
    margin: 50px auto 100px;
    row-gap: 45px;
  }
}