.menu__item {
  padding-right: 50px;
}

@media screen and (max-width: 984px) {
  .menu__item {
    padding-right: 40px;
  }
}
@media screen and (max-width: 568px) {
  .menu__item {
    padding-right: 20px;
  }
}

.menu__item:last-of-type {
  padding-right: 0;
}