.menu__nav ol {
  display: flex;
  flex-direction: row;
  list-style-type: none;
  margin: 0;
  padding: 41px 0;
}

@media screen and (max-width: 984px) {
  .menu__nav ol {
    padding: 26px 0;
  }
}
@media screen and (max-width: 568px) {
  .menu__nav ol {
    padding: 18px 0;
  }
}