.info__container {
  background-color: #0f205e;
  max-width: 1280px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  color: #f7f7f8;
}

@media screen and (max-width: 629px) {
  .info__container {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}