.reviews__title {
  font-size: 40px;
  line-height: 40px;
  font-weight: 500;
  margin: 100px 0 0 0;
  text-align: center;
}

@media screen and (max-width: 984px) {
  .reviews__title {
    font-size: 32px;
    line-height: 32px;
    margin-top: 60px;
  }
}
@media screen and (max-width: 568px) {
  .reviews__title {
    font-size: 18px;
    line-height: 18px;
  }
}