.info__contacts {
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
  margin: 0;
  padding-bottom: 20px;
}

@media screen and (max-width: 1050px) {
  .info__contacts {
    font-size: 14px;
    line-height: 18px;
    padding-bottom: 15px;
  }
}

@media screen and (max-width: 720px) {
  .info__contacts {
    font-size: 12px;
    line-height: 16px;
    padding-bottom: 10px;
  }
}

@media screen and (max-width: 629px) {
  .info__contacts {
    text-align: center;
  }
}