.header__info__type_number {
  font-size: 20px;
  font-weight: 500;
  margin: 0 0 10px 0;
}

@media screen and (max-width: 984px) {
  .header__info__type_number {
    font-size: 16px;
    margin: 0 0 4px 0;
  }
}
@media screen and (max-width: 568px) {
  .header__info__type_number {
    font-size: 10px;
    margin: 0;
  }
}