.header__logo__type_title {
  text-transform: uppercase;
  font-weight: 400;
  font-size: 18px;
  line-height: 18px;
  margin: 0;
  align-self: center;
}

@media screen and (max-width: 984px) {
  .header__logo__type_title {
    font-size: 14px;
    line-height: 14px;
  }
}
@media screen and (max-width: 568px) {
  .header__logo__type_title {
    font-size: 10px;
    line-height: 12px;
    width: 76px;
  }
}