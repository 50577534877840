.footer__container{
  background-color: #140a4d;
  max-width: 1280px;
  margin: 0 auto;
  color: #f7f7f8;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 150px;
}

@media screen and (max-width: 984px) {
  .footer__container {
    padding: 0 70px;
  }
}

@media screen and (max-width: 568px) {
  .footer__container {
    padding: 0 25px;
  }
}