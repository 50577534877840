.popup__input {
  background-color: #E9EAEE;
  border: rgba(255, 255, 255, 0.4) 1px solid;
  border-radius: 40px;
  width: 440px;
  height: 50px;
  box-shadow: -5px -5px 10px #FAFBFF inset, 5px 5px 10px #A6ABBD inset;
  margin-bottom: 20px;
  padding: 0;
  font-size: 18px;
  outline: none;
  color: #140a4d;
  box-sizing: border-box;
  padding-left: 20px;
}

@media screen and (max-width: 984px) {
  .popup__input {
    width: 400px;
    height: 45px;
    margin-bottom: 15px;
    padding-left: 25px;
    font-size: 16px;
  }
}

@media screen and (max-width: 568px) {
  .popup__input {
    width: 240px;
    height: 40px;
    margin-bottom: 15px;
    font-size: 14px;
    padding-left: 20px;
    font-size: 14px;
  }
}