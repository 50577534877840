.tired__title {
  font-size: 40px;
  line-height: 40px;
  font-weight: 500;
  margin: 100px 125px 0;
  text-align: center;
}

@media screen and (max-width: 984px) {
  .tired__title {
    font-size: 32px;
    line-height: 32px;
    margin: 60px 70px 0;
  }
}

@media screen and (max-width: 643px) {
  .tired__title {
    font-size: 26px;
  }
}

@media screen and (max-width: 568px) {
  .tired__title {
    font-size: 18px;
    line-height: 18px;
    margin: 60px 25px 0;
  }
}