.tired__card__image {
  width: 70px;
  height: 70px;
}

@media screen and (max-width: 1190px) {
  .tired__card__image {
    width: 50px;
    height: 50px;
  }
}

@media screen and (max-width: 568px) {
  .tired__card__image {
    width: 40px;
    height: 40px;
  }
}