.lead__slogan {
  font-size: 28px;
  display: block;
  max-width: 800px;
  line-height: 32px;
  text-align: center;
  padding: 50px 150px 103px;
  margin: 0;
}

@media screen and (max-width: 984px) {
  .lead__slogan {
    font-size: 18px;
    line-height: 20px;
    padding: 35px 70px 65px;
  }
}
@media screen and (max-width: 568px) {
  .lead__slogan {
    font-size: 12px;
    line-height: 16px;
    padding: 50px 25px 90px;
  }
}