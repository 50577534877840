.popup__form {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #F7F7F8;
  border-radius: 20px;
  padding: 0 35px;
}

@media screen and (max-width: 984px) {
  .popup__form {
    padding: 0 25px;
  }
}

@media screen and (max-width: 568px) {
  .popup__form {
    padding: 0 15px;
  }
}