.header__info__type_time {
  text-align: right;
  font-size: 14px;
  margin: 0;
  line-height: 20px;
}

@media screen and (max-width: 984px) {
  .header__info__type_time {
    font-size: 12px;
    line-height: 16px;
  }
}
@media screen and (max-width: 568px) {
  .header__info__type_time {
    font-size: 8px;
    line-height: 10px;
  }
}