.popup__title {
  font-size: 28px;
  margin: 40px 0 30px;
  font-weight: 500;
  color: #140a4d;
}

@media screen and (max-width: 984px) {
  .popup__title {
    font-size: 20px;
    margin: 35px 0 25px;
  }
}

@media screen and (max-width: 568px) {
  .popup__title {
    font-size: 14px;
    margin: 30px 0 20px;
  }
}