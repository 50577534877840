.tired__container{
  max-width: 980px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 70px auto 70px;
  padding: 0 150px;
}

@media screen and (max-width: 984px) {
  .tired__container {
    max-width: 844px;
    margin: 55px auto 55px;
    padding: 0 70px;
  }
}

@media screen and (max-width: 744px) {
  .tired__container {
    margin-bottom: 40px;
}
}

@media screen and (max-width: 568px) {
  .tired__container {
    margin: 40px 0 40px 0;
    padding: 0 25px;
    flex-direction: column;
    align-self: center;
    width: 270px;
  }
}