@font-face {
    font-family: 'Malgun Gothic';
    font-style:  normal;
    font-weight: 400;
    font-display: swap;
    src: url("Malgun\ Gothic.woff2") format("woff2"),
         url("Malgun\ Gothic.woff") format("woff");
  }
@font-face {
    font-family: 'Malgun Gothic';
    font-style:  normal;
    font-weight: 500;
    font-display: swap;
    src: url("Malgun\ Gothic\ Bold.woff2") format("woff2"),
         url("Malgun\ Gothic\ Bold.woff") format("woff");
  }