.statistics__title {
  font-size: 80px;
  line-height: 80px;
  font-weight: 400;
  text-align: center;
  margin: 0 0 30px 0;
}

@media screen and (max-width: 984px) {
  .statistics__title {
    font-size: 50px;
    line-height: 50px;
    margin-bottom: 14px;
  }
}
@media screen and (max-width: 568px) {
  .statistics__title {
    margin-bottom: 10px;
  }
}