.connection__form {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 80px 150px 150px;
}

@media screen and (max-width: 984px) {
  .connection__form {
    margin: 60px 70px 100px;
  }
}

@media screen and (max-width: 568px) {
  .connection__form {
    margin: 40px 25px 100px;
  }
}