.connection__title {
  padding: 100px 150px 0;
  font-size: 32px;
  font-weight: 500;
  line-height: 40px;
  margin: 0;
  text-align: center;
}
.connection__title_br {
  display: none;
}
@media screen and (max-width: 984px) {
  .connection__title {
    font-size: 24px;
    line-height: 30px;
    padding: 60px 70px 0;
  }
  .connection__title_br {
    display: block;
  }
}

@media screen and (max-width: 568px) {
  .connection__title {
    font-size: 18px;
    line-height: 24px;
    padding: 60px 25px 0;
  }
}